/* Styles for the full-width layout */
.page-container {
  display: flex;
  max-width: 100%;
  /* Set maximum width to ensure full-width layout */
  background-color: #E0FCFA;
  /* Light blue background color */
  color: #333;
  /* Dark text color */
  margin: 0 auto; /* Center the content horizontally */

}

/* Styles for the sidebar menu */
.sidebar {
  flex: 0 0 200px;
  /* Adjust the width of the sidebar as needed */
  padding: 20px;
  background-color: #0788AA;
  /* Dark blue background color */
  position: sticky;
  top: 1px;
  /* Adjust the top value to add some space between the sidebar and top of the page */
  height: 100vh;
  overflow-y: auto;
  border-radius: 10px;
}

.sidebar h3 {
  margin-bottom: 10px;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  margin-bottom: 5px;
}

.sidebar a {
  text-decoration: none;
  color: #333;
  transition: color 0.2s ease;
}

.sidebar a:hover {
  color: #007bff;
}

/* Style the sidebar buttons */
.sidebar button {
  display: block;
  width: 100%;
  padding: 10px 20px;
  margin-bottom: 10px;
  background-color: #24BFCB;
  /* Light turquoise background color */
  color: #fff;
  /* White text color */
  border: none;
  border-radius: 8px;
  /* Rounded corners */
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

/* Hover effect for buttons */
.sidebar button:hover {
  background-color: #35C7D8;
  /* Lighter turquoise background color on hover */
  transform: scale(1.05);
  /* Slightly scale up the button on hover */
}

/* Active effect for buttons */
.sidebar button:active {
  background-color: #0788AA;
  /* Darker turquoise background color when button is active (pressed) */
  transform: scale(0.95);
  /* Slightly scale down the button when active */
}

/* Styles for the main content area */
.main-content {
  flex: 1;
  /* Allow main content to take available space */
  padding: 20px;
  /* Add some top padding to make space for the sticky sidebar */
  padding-top: 0px;
  /* Adjust this value as needed */
  max-width: 900px; /* Set a max-width for the main content */
  margin: 0 auto; /* Center the content horizontally */
  overflow-wrap: break-word; /* Add word break to prevent overflow */
}


.main-content h1 {
  font-size: 28px;
  margin-bottom: 20px;
}

.main-content p {
  line-height: 1.6;
}

/* Style the horizontal lines */
hr {
  border: 1px solid #35C7D8;
  /* Light turquoise line color */
}

/* Add a media query to hide the sidebar on screens with max-width 768px (mobile devices) */
@media (max-width: 768px) {
  .sidebar {
    display: none;
  }
}

/* Media query to adjust max-width and margin on smaller screens */
@media (max-width: 480px) {
  .main-content {
    max-width: calc(100% - 40px); /* Adjust margin for smaller screens */
    margin-right: 20px; /* Keep some margin on the right side */
  }
}
/* Additional styles for other elements on the Documentation pages */
/* Customize as needed */