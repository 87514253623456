body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.app-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  height: 100vh; /* Set height to 100vh */
}

.header {
  text-align: center;
  padding: 20px 0;
}

.header h1 {
  font-size: 24px;
  margin: 0;
}

.project-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 20px;
}

.project-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  background-color: #f0f0f0;
  border-radius: 8px;
  font-size: 18px;
  text-decoration: none;
  color: #333;
  transition: background-color 0.2s ease;
}

.project-button:hover {
  background-color: #ddd;
}

.title-link {
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
}

.title-link:hover {
  text-decoration: underline;
}

/* Media query to adjust max-width and margin on smaller screens */
@media (max-width: 480px) {
  .header {
    max-width: calc(100% - 40px); /* Adjust margin for smaller screens */
    margin-right: 20px; /* Keep some margin on the right side */
  }
}